import { loginUrl } from '../../../store.config'

const MainClubeDecathlon = () => {
  const bgImage =
    'https://decathlonpro.vteximg.com.br/arquivos/ids/2600745/home-club-desktop.jpg?v=637430525191100000'

  const backImgStyle = {
    backgroundImage: `url(${bgImage})`,
  }

  return (
    <section
      className="clube-main-section h-[1000px] bg-cover pp:bg-center md:bg-right bg-no-repeat"
      style={backImgStyle}
    >
      <div className="overlay-container bg-black/[.5] h-full pp:w-full pp:py-[5%] pp:px-[10%] md:w-[55%] flex flex-col items-center md:pt-[5%] md:pr-[6%] md:pb-0 md:pl-[15%] md:gap-1">
        <div className="clube-logo w-[300px]">
          <img
            src="https://decathlonpro.vteximg.com.br/arquivos/ids/2591582/clube-decathlon-768x360.png?v=637394270635900000"
            alt="Logo Clube Decathlon"
            width={768}
            height={360}
            className="w-full h-auto"
          />
        </div>
        <div className="text-white clube-main-title">
          <h2
            className="text-xl font-bold text-center uppercase tracking-[6px] my-[.67em] mx-0"
            style={{ fontStretch: 'condensed' }}
          >
            mais benefícios
            <br />
            mais esporte
          </h2>
        </div>
        <div
          className="clube-main-description text-white font-bold text-xl leading-[1.14] text-center"
          style={{ fontStretch: 'condensed' }}
        >
          O Clube Decathlon é o nosso programa de relacionamento gratuito que
          oferece benefícios exclusivos como descontos, sorteios e serviços para
          os esportistas cadastrados.
          <br />
          <br />
          Para aproveitar os benefícios basta se cadastrar e se identificar em
          suas compras através do CPF.
        </div>
        <hr className="w-full !border-t-[3px] !border-[#036db3] my-[20px]" />
        <div className="text-white clube-main-button-container">
          <div className="clube-main-button-content">
            <p
              style={{ fontStretch: 'condensed' }}
              className="text-xl font-extrabold tracking-[6px] text-center uppercase"
            >
              quanto mais jogarmos juntos mais personalizada será sua
              experiência com a gente ;)
            </p>
          </div>
          <div className="flex justify-center clube-main-button-btn">
            <a
              style={{ fontStretch: 'condensed' }}
              className="clube-main-button relative flex justify-center bg-[#036db3] mt-[80px] text-xl font-extrabold pp:tracking-[unset] lg:tracking-[4px] text-center uppercase border-[3px] border-white py-[15px] pp:px-[30px] md:pr-[40px] md:pl-[30px]"
              href={`${loginUrl}?returnUrl=https://www.decathlon.com.br`}
            >
              faça parte do clube!
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MainClubeDecathlon
